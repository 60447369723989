import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    openRounds: [],
    currentGolfer: {},
    roundInPlay: {},
    teeInPlay: {},
    user: { 
      authNeeded: true,
      authId: "",
      email: "",
      roles: [],
      golferId: "",
      manager: false,
      firstName: "",
      lastName: "",
      handicap: 0,
      league: "",
      team: ""
    }
  },
  getters: {
    getOpenRounds: state => state.openRounds,
    getCurrentGolfer: state => state.currentGolfer,
    getUser: state => state.user
  },
  mutations: {
    // sync only
    clearRounds(state){
      state.openRounds = [];
    },
    addRound(state, round){
      state.openRounds.push(round);
    },
    updateRound(state, round){
      const roundIdx = state.openRounds.findIndex(oRound => {
        oRound.roundId === round.roundId
      });
      if (roundIdx > -1) {
        state.openRounds[roundIdx] = round;
      }
    },
    setGolfer(state, golfer){
      state.currentGolfer = golfer;
    },
    setRoundInPlay(state, round){
      state.roundInPlay = round;
    },
    setTeeInPlay(state, tee){
      state.teeInPlay = tee;
    },
    setUser(state, user) {

      console.log("In setUser Store");
      //TODO: USE THE USER ROLES FROM AAD
      if (user.userId && user.userDetails) {
        state.user.authNeeded = false;
        state.user.authId = user.userId;
        state.user.email = user.userDetails;
        state.user.roles = user.userRoles;
      } else {
        console.log("User Not Here!")
      }
      console.log(user);
      console.log("State User");
      console.log(state.user);
      //state.user = user;
    },
    setGolfUser(state, dbgolfer) {
      console.log(dbgolfer.golferId);
      state.user.golferId = dbgolfer.golferId;
      state.user.team = dbgolfer.team;
      state.user.manager = dbgolfer.manager;
      state.user.handicap = dbgolfer.handicap;
      state.user.league = dbgolfer.league;
      state.user.firstName = dbgolfer.firstName;
      state.user.lastName = dbgolfer.lastName;
      console.log("User dbgolfer Set!")
      console.log(state.user);
    }

  },
  actions: {
    // async
  },
  modules: {
  }
})
