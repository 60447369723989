import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    beforeEnter(to, from, next) {
      console.log("to:", to)
      console.log("from:", from)
      console.log("next:", next)
      window.location.href = "/.auth/login/aad?post_login_redirect_uri=/";
    }
  },
  {
    path: '/userDebug',
    name: 'userDebug',
    meta: { requiresAuth: false },
    // Fake auth and user to be able to debug app...
    component: () => import('../views/DebugUserView.vue')
  },
  {
    path: '/',
    name: 'userInfo',
    meta: { requiresAuth: false },
    // sTART paGE AND SETS USERS TO LOGIN AND DETECT IF THEY ARE A GOLFER IN THE LEAGUE.
    // hARD CODED TO vANA RIGHT NOWS.
    component: () => import('../views/UserInfoView.vue')
  },
  {
    path: '/manageGolfers',
    name: 'manageGolfers',
    meta: { requiresMgr: true },
    // Should be for Managers to edit/add golfers
    component: () => import('../views/ManageGolfersView.vue')
  },
  {
    path: '/userHome',
    name: 'userHome',
    meta: { requiresAuth: true },
    // Should be the HomePage after the user logs in
    component: () => import('../views/UserHomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/resumeRound',
    name: 'resumeRound',
    meta: { requiresAuth: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "resume" */ '../views/UpdateLeagueRound.vue')
  },
  // {
  //   path: '/startRound',
  //   name: 'startRound',
  //   meta: { requiresAuth: true },
  //   // Select 4 Golfers and Starts a round
  //   component: () => import('../views/StartRound.vue')
  // },
  {
    path: '/updateRound',
    name: 'updateRound',
    meta: { requiresMgr: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "resume" */ '../views/UpdateLeagueRound.vue')
  },
  {
    path: '/leagueRound',
    name: 'leagueRound',
    meta: { requiresAuth: true },
    // Select 4 Golfers and Starts a round
    component: () => import('../views/StartLeagueRound.vue')
  },
  {
    path: '/scheduleRound',
    name: 'scheduleRound',
    meta: { requiresMgr: true },
    // Select Date Time and matches
    component: () => import('../views/ScheduleRound.vue')
  },
  {
    path: '/setGolfers',
    name: 'setGolfers',
    meta: { requiresAuth: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "start" */ '../views/SetGolfers.vue')
  },
  {
    path: '/showRounds',
    name: 'showRounds',
    meta: { requiresAuth: true },
    // Should be the HomePage after the user logs in
    component: () => import('../views/ShowRounds.vue')
  },
  {
    path: '/standings',
    name: 'standings',
    meta: { requiresAuth: true },
    // Should be the HomePage after the user logs in
    component: () => import('../views/Standings.vue')
  },
  {
    path: '/showScoreCards',
    name: 'showScoreCards',
    meta: { requiresAuth: true },
    // Should be the HomePage after the user logs in
    component: () => import('../views/ShowScoreCard.vue')
  },
  {
    path: '/scoreRound',
    name: 'scoreRound',
    meta: { requiresAuth: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "score" */ '../views/ScoreRound.vue')
  }
]

const router = new VueRouter({
  routes
});

  router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
      if (store.state.user.authNeeded) {
        console.log("AUTHENTICATE!");
        console.log(store.state.user.authNeeded);
        console.log(store.state.user);
        next('/');
      } else {
        if (store.state.user.golferId) {
          console.log("USER IS AUTHENTIC!");
          console.log(store.state.user);
          next();
        } else {
          console.log("USER IS not-Golfer!");
          console.log(store.state.user);
          next('/');
        }
      }
    } else {
      console.log("Free PAGE!");
      next();
    }
    if(to.matched.some(record => record.meta.requiresMgr)) {
      if (store.state.user.authNeeded) {
        console.log("AUTHENTICATE!");
        console.log(store.state.user.authNeeded);
        console.log(store.state.user);
        next('/');
      } else {
        if (store.state.user.manager) {
          console.log("USER IS Manager!");
          console.log(store.state.user);
          next();
        } else {
          console.log("USER IS not-Golfer!");
          console.log(store.state.user);
          next('/');
        }
      }
    }
  });

export default router
