<template>
  <v-app id="inspire">
    <v-app-bar app color="primary" dark src="golfheader.jpg" short collapse-on-scroll scroll-target="#scrolling-techniques-3" fade-img-on-scroll prominent class="no-print">
      <template v-slot:img="{ props }">
        <v-img v-bind="props" gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"></v-img>
      </template>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-app-bar-title>Golf Clans</v-app-bar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app class="teal lighten-5 no-print">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Golf Clans
          </v-list-item-title>
          <v-list-item-subtitle>
            Golf League Manager
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item v-for="item in items" :key="item.title" :to="item.to" link>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main class="show-print">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    drawer: null,
    items: [
      { title: 'League Info', icon: 'mdi-help-box', to: 'about' },
      { title: 'User Info', icon: 'mdi-card-account-details', to: '/' },
      { title: 'Standings', icon: 'mdi-podium', to: 'standings' },
      { title: 'Show Rounds', icon: 'mdi-table-multiple', to: 'showRounds' },
      { title: 'Play Round', icon: 'mdi-golf-cart', to: 'resumeRound' },
      { title: 'Schedule Round', icon: 'mdi-clipboard-text-clock', to: 'scheduleRound' },
      { title: 'Manage Users', icon: 'mdi-account-group', to: 'manageGolfers' },
    ],
    golfer: {}
  }),
  async created() {
    try {
      console.log("App Vue", this.$store.state.user);
    } catch (error) {
      console.log("App Vue Error", error);
    }
  }

}
</script>