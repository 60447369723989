import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#43A047',
            secondary: '#C0CA33',
            anchor: '#2E7D32',
          },
        },
      }
});
